document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-dcp-music-contest-entry-page')) {
    // アーティスト名から作品名を生成する
    const arrangeContestArtistNameField = document.querySelector('.js-arrange-contest-artist-name')
    const arrangeContestAudioNameField = document.querySelector('.js-arrange-contest-audio-name')
    const refreshArrangeContestAudioNameField = () => {
      arrangeContestAudioNameField.value = `ドスパラのテーマ ${arrangeContestArtistNameField.value} ver.`
    }
    arrangeContestArtistNameField.addEventListener('input', refreshArrangeContestAudioNameField)
    refreshArrangeContestAudioNameField()
  }
})
