document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-dcp-music-contest')) {
    // Twitterウィジェットを遅延読み込み
    const renderTwitterWidget = () => {
      if (window.scrollY > window.innerHeight * 4) {
        const twitterWidgetScriptElement = document.createElement('script')
        twitterWidgetScriptElement.src = 'https://platform.twitter.com/widgets.js'
        twitterWidgetScriptElement.async = true
        document.body.appendChild(twitterWidgetScriptElement)
        window.removeEventListener('scroll', renderTwitterWidget)
        window.removeEventListener('resize', renderTwitterWidget)
      }
    }
    window.addEventListener('scroll', renderTwitterWidget)
    window.addEventListener('resize', renderTwitterWidget)

    // モバイルでは動作させない
    if (!document.querySelector('.js-dcp-music-contest-mobile')) {
      // アイキャッチ背景の視差効果
      const bgLines = document.querySelectorAll('.dcp-music-contest__bg-line')
      const bgLineSmoothScroll = () => {
        bgLines.forEach((bgLine) => {
          const positionTop = bgLine.getBoundingClientRect().top
          const positionBottom = bgLine.getBoundingClientRect().bottom
          bgLine.style.backgroundPositionY = `${(positionTop / (positionBottom - positionTop)) * -40}vw`
        })
      }
      window.addEventListener('scroll', bgLineSmoothScroll)
      window.addEventListener('resize', bgLineSmoothScroll)
      bgLineSmoothScroll()

      // 音符背景の視差効果
      const bgMusics = document.querySelectorAll('.dcp-music-contest__bg-music')
      const bgMusicSmoothScroll = () => {
        const scrollPercentage = `${window.scrollY / window.innerHeight * -75}%`
        bgMusics.forEach((bgMusic) => bgMusic.style.backgroundPositionY = scrollPercentage)
      }
      window.addEventListener('scroll', bgMusicSmoothScroll)
      window.addEventListener('resize', bgMusicSmoothScroll)
      bgMusicSmoothScroll()
    }
  }
})
